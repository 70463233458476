import React from "react"
import { Helmet } from "react-helmet"
import Seo from "../seo"
import SupportSection1 from "./section-support1"
import SupportSection2 from "./section-support2"

const Support = () => {
  return (
    <div>
      <Seo
        title="Support"
        description="Professional support, performance audits .Find answers in our comprehensive docs or learn something new in one of our how-to."
      />
      <Helmet prepend>
        <meta
          property="og:title"
          content="MAXapps Support: The MAXapps Way of Building"
        />
        <meta
          property="og:description"
          content="Discover How MAXapps Can Help Support Your Team."
        />
        <meta
          property="og:image"
          content="https://maxapps-maximo.com/og/support.jpg"
        />
        <meta property="og:url" content="https://maxapps-maximo.com/support" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@maxapps" />
        <meta
          name="twitter:title"
          content="MAXapps Support: The MAXapps Way of Building"
        />
        <meta
          name="twitter:description"
          content="Discover How MAXapps Can Help Support Your Team."
        />
        <meta
          name="twitter:image"
          content="https://maxapps-maximo.com/og/support.jpg"
        />
      </Helmet>
      <SupportSection1 />
      <SupportSection2 />
    </div>
  )
}

export default Support
