import React from "react"

import ContactForm from "../../forms/contact-form"

import "./index.scss"
const SupportSection2 = ({ _logoSection }) => {
  // let frontmatter = {}

  // try {
  //   const result = useStaticQuery(graphql`
  //     {
  //       mdx(fileAbsolutePath: { regex: "/(support-s2)/" }) {
  //         frontmatter {
  //           title1
  //           title2
  //           title3
  //           title4
  //           title5
  //           t0
  //           t1
  //           t2
  //           t3
  //           t4
  //           t5
  //           title6
  //           title6Description
  //           titleButton
  //         }
  //       }
  //     }
  //   `)

  //   const frontmatter = result.mdx.frontmatter
  // } catch (error) {
  //   const frontmatter = _logoSection
  // }
  return (
    <div
      id="SupportSection2"
      className="SupportSection2 d-flex justify-content-center"
      data-testid="SupportSection2"
    >
      {/* <div className="box col-12 col-md-10 col-lg-8">
        <ContactForm />
      </div> */}
    </div>
  )
}

export default SupportSection2
