import React from "react"
import Layout from "../components/layout/t1/layout"
import Support from '../components/Support'

const HomePage = () => {
  return (
    <Layout>
      <Support/>
    </Layout>
  )
}

export default HomePage
