import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { BsArrowRightShort } from "react-icons/bs"
import YellowButton from "../../home/buttons/yellow-button"
import { BsArrowRight } from "react-icons/bs"
import "./index.scss"

const SupportSection1 = ({ _logoSection, _section1 }) => {
  let frontmatter = {}
  let section1 = []

  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(support-s1)/" }) {
          exports {
            data {
              ImgSrc
              Title
              description
              to
            }
          }
          frontmatter {
            title1
            title2
            title3
            title4
            Description
          }
        }
      }
    `)
    section1 = result.mdx.exports.data

    frontmatter = result.mdx.frontmatter
  } catch (error) {
    frontmatter = _logoSection
    section1 = _section1
  }
  return (
    <div
      id="SupportSection1"
      className="SupportSection1 text-start"
      data-testid="SupportSection1"
    >
      <div className="text-center">
        <h1> {frontmatter?.title1}</h1>
        <h2>
          <span>{frontmatter?.title2}</span>
        </h2>
      </div>
      <div className="row py-5 justify-content-center">
        {section1?.map((item, index) => {
          return (
            <div className="col-12 col-lg-6 py-5 support-cards" key={index}>
              <div className="row">
                <div className="col-12 text-center col-md-2 text-md-start ">
                  <img
                    src={item.ImgSrc}
                    alt={`IBM Maximo: ${item.Title} `}
                    title={`IBM Maximo: ${item.Title} `}
                  />
                </div>
                <div className="col-12 text-center col-md-10 text-md-start ">
                  <h3>
                    <Link to={item.to} className="support-card-link">
                      {item.Title}
                      <BsArrowRightShort
                        className="support-card-arrow"
                        size={40}
                      />
                    </Link>
                  </h3>
                  <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="text-center">
        <h2>{frontmatter?.title4}</h2>
        <div className="d-flex justify-content-center col-12 text-start">
          <div className=" mt-3 py-2">
            <YellowButton data={"Contact Us"} size={"180px"} to="/contact/" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupportSection1
